<template>
  <article-wrapper heading="Contact Me">
    <div class="grid fit-400 fit-300-phone">
      <div class="profile-card">
        <img
          class="rounded-bottom-left"
          loading="lazy"
          alt="Profile photo of Dan Chitwood with his surveying
              board in front of a large stone drainage ditch."
          :src="require('@/assets/photos/original/dan-survey-pad.jpg')"
        >
        <p>
          <span>Dan Chitwood - Landscape Architect</span>
        </p>
        <p>
          <a class="flair" href="tel:+15403149040">
            <span>(540) 314-9040</span>
          </a>
        </p>
        <p>
          <a class="flair" href="mailto:chitwoodlandscape@gmail.com">
            <span>chitwoodlandscape@gmail.com</span>
          </a>
        </p>
        <p>
          PO Box 20912
          <br>
          Roanoke, VA 24018
        </p>
      </div>
      <div class="form muted">
        <contact-form />
      </div>
    </div>
  </article-wrapper>
</template>

<script>
import ArticleWrapper from '@/components/ArticleWrapper';
import ContactForm from '@/components/ContactForm';

export default {
  components: {
    ArticleWrapper,
    ContactForm,
  },
};
</script>

<style lang="scss" scoped>
article {
  padding-bottom: 100px;
}

.profile-card {
  p {
    margin: 0.5rem 0 0.5rem 0;
  }
}

.form {
  text-align: left;
  padding: 36px;
  border-radius: var(--rc);
}
</style>
