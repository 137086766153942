<template>
  <div id="form-wrapper">
    <form
      id="contact-form"
      action="https://formspree.io/f/xanwodyr"
      method="post"
    >
      <div class="input-field" :class="{ active: !!name, focused: focus.name }">
        <label class="form-label" for="name">Name</label>
        <input
          id="name"
          v-model="name"
          type="text"
          name="name"
          placeholder="Name *"
          @focus="focus.name = true"
          @blur="focus.name = false"
        >
      </div>
      <div class="input-field" :class="{ active: !!email, focused: focus.email }">
        <label class="form-label" for="email">Email</label>
        <input
          id="email"
          v-model="email"
          type="email"
          name="email"
          placeholder="Email *"
          @focus="focus.email = true"
          @blur="focus.email = false"
        >
      </div>
      <div class="input-field" :class="{ active: !!phone, focused: focus.phone }">
        <label class="form-label" for="phone">Phone</label>
        <input
          id="phone"
          v-model="phone"
          type="tel"
          name="phone"
          placeholder="Phone"
          @focus="focus.phone = true"
          @blur="focus.phone = false"
        />
      </div>
      <div class="input-field" :class="{ active: !!spouse, focused: focus.spouse }">
        <label class="form-label" for="spouse">Spouse/Partner</label>
        <input
          id="spouse"
          v-model="spouse"
          type="text"
          name="spouse"
          placeholder="Spouse/Partner"
          @focus="focus.spouse = true"
          @blur="focus.spouse = false"
        />
      </div>
      <div class="input-field" :class="{ active: !!address, focused: focus.address }">
        <label class="form-label" for="address">Address</label>
        <input
          id="address"
          v-model="address"
          type="text"
          name="address"
          placeholder="Address"
          @focus="focus.address = true"
          @blur="focus.address = false"
        />
      </div>
      <div class="input-field" :class="{ active: !!message, focused: focus.message }">
        <label class="form-label" for="message">Project Details</label>
        <textarea
          id="message"
          v-model="message"
          name="message"
          placeholder="Project Details *"
          @focus="focus.message = true"
          @blur="focus.message = false"
        ></textarea>
      </div>
      <div class="input-field input-field-submit">
        <input type="submit" value="Submit">
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'ContactForm',
  data: () => ({
    errors: [],
    name: null,
    email: null,
    phone: null,
    spouse: null,
    address: null,
    message: null,
    focus: {
      name: false,
      email: false,
      phone: false,
      spouse: null,
      address: null,
      message: false,
    },
  }),
};
</script>

<style lang="scss" scoped>
#form-wrapper {
  height: 100%;
}

form {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .input-field {
    position: relative;
    margin-top: 24px;

    &.focused {
      input, textarea {
        border: 2px solid #333;
        outline: none;
      }

      input::placeholder, textarea::placeholder {
        color: transparent;
      }
    }

    &:not(.focused) {
      input, textarea {
        margin-top: 1px;
        margin-bottom: 1px;
      }
    }

    &.focused, &.active {
      input, textarea {
        padding: 25px 10px 5px 10px;
      }

      label {
        display: block;
        position: absolute;
        top: 3px;
        left: 10px;
        margin-top: 0px;
        font-size: 13px;
      }
    }
  }

  input:not([type="submit"]), textarea {
    border: 1px solid #B0B0B0;
    border-radius: var(--rc);
    color: #222;
    font-size: 16px;
    width: 100%;
    max-width: 100%;
    margin: 0;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  }

  textarea {
    resize: vertical;
  }

  input[type="text"], input[type="email"], input[type="date"], input[type="tel"], textarea {
    padding: 15px 25px 15px 10px;
  }

  .input-field-submit {
    text-align: right;

    input[type="submit"] {
      width: auto;
    }
  }
}

label {
  display: none;
}
</style>
